.MuiChartsLegend-series text {
  fill: #fff !important;
  font-size: 18px !important;
}

.css-1mhcdve-MuiPieArc-root {
  stroke: transparent !important;
}

@media only screen and (max-width: 1024px) {
  .MuiChartsLegend-root.MuiChartsLegend-column {
    transform: translate(-155px, 0px);
  }
}
