.settings {
  display: flex;
  padding: 0 90px;
  column-gap: 35px;
  position: relative;
  margin: 40px auto 80px;
  max-width: 1210px;

  &__navigation, &__navigationHide {
    max-width: 310px;
    display: flex;
    flex-direction: column;
    row-gap: 14px;
    width: 100%;
    min-width: 260px;

    span {
      font-size: 24px;
      font-weight: 500;
      line-height: 39px;
    }
  }

  &__content {
    width: 100%;
  }
}
.stats ul {
  display: flex;
  row-gap: 10px;
  column-gap: 10px;
  width: 340px;
  justify-content: center;
  margin: 0 auto 16px;

  li {
    font-size: 11px;
    font-weight: 400;
    line-height: 10px;
    padding: 6px;
    background: #60606026 !important;
    border-radius: 5px;
    color: #C7C7C7;
  }
}

@media only screen and (max-width: 1024px) {
  .settings {
    margin: 0;
    padding: 24px 0;
    display: block;

    &__navigation, &__navigationHide {
      width: 100%;
      padding: 0 90px 100px;
      max-width: 100%;
      row-gap: 8px;
    }

    &__content, &__contentActive {
      position: absolute;
      padding: 24px 90px;
      left: 200vw;
      top: 0;
      width: 100vw;
      min-height: 100vh;
      height: 100%;
      background-color: #0C1223;
      transition: all .1s;
    }

    &__contentActive {
      left: 0;
    }
  }

  .link, .mobExit {
    width: 100%;
    height: 56px;
    background-color: #131B38;
    border-radius: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    opacity: 1 !important;

    span {
      font-size: 18px;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: -0.02em;
    }
  }

  .settingsHeader {
    margin-top: 24px;
    border-radius: 16px;
    margin-bottom: 12px;
    position: relative;
    padding: 16px;

    &__links {
      padding: 16px;
      background-color: #172144;
      border-radius: 16px;

      p {
        font-size: 22px;
        font-weight: 700;
        line-height: 19px;
        margin-bottom: 26px;
      }

      a {
        width: 100%;
        height: 34px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        opacity: 1 !important;

        span {
          font-size: 16px;
          font-weight: 400;
          line-height: 16px;
        }
      }

      a:last-child {
        align-items: flex-end;
      }
    }
  }

  .userPhoto {
    position: absolute;
    left: 50%;
    top: 0;
    width: 60px;
    height: 60px;
    border: 24px;
    transform: translateX(-50%) translateY(-55%);
  }

  .userName {
    margin: 16px 0 16px;
    font-size: 40px;
    font-weight: 700;
    line-height: 35px;
    text-align: center;
  }

  .decor {
    display: block;
    height: 1px;
    width: 100%;
    opacity: 0.1;
  }
}

.themeWrapper {
  padding: 11px 16px;
  max-height: 60px;
  height: 60px;
  border-radius: 18px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.theme {
  display: flex;
  align-items: center;
  column-gap: 10px;

  span {
    font-size: 18px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.02em;
  }
}

.changer__switcher {
  display: flex;
  align-items: center;
}

.switcherBox, .switcherBoxActive {
  width: 32px;
  height: 20px;
  border-radius: 50px;
  background-color: #78788029;
  position: relative;
  transition: all .2s;
}

.switcherFalse, .switcherTrue {
  width: 17px;
  height: 17px;
  background-color: #fff;
  pointer-events: none;
  position: absolute;
  border-radius: 50%;
  box-shadow: 0px 3px 1px 0px #0000000F;
  box-shadow: 0px 3px 8px 0px #00000026;
  left: 1px;
  top: 2px;
  transition: all .2s;
}

.switcherTrue {
  left: calc(100% - 19px);
}

.loader span {
  font-size: 6px;
}


@media only screen and (max-width: 743px) {
  .settings {
    &__navigation, &__navigationHide {
      padding: 0 16px 100px;
    }

    &__navigationHide {
      max-height: 80vh;
    }

    &__content, &__contentActive {
      position: absolute;
      padding: 24px 16px;
    }
  }

  .settingsHeader {
    margin-top: 0;
  }

  .userName {
    font-size: 32px;
    font-weight: 500;
    line-height: 28px;
  }
}
