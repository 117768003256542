.tradingPlatform {
  padding: 0 126px 260px;
  background-repeat: no-repeat;
  background-size: 90%;
  background-position: 50% 50%;


  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 66px;
    align-items: flex-start;

    h3 {
      max-width: 50%;
      font-size: 65px;
      font-weight: 500;
      line-height: 57px;
    }

    p {
      margin-top: 12px;
      font-size: 18px;
      line-height: 25px;
      opacity: 0.5;
      max-width: 370px;
    }
  }

  &__content {
    border-radius: 40px;
    position: relative;
    z-index: 2;
  }
}



.decor {
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  position: absolute;
  width: 80%;
  height: 170%;
  z-index: 1;
  object-fit: cover;
}

@media only screen and (max-width: 1280px) {
  .tradingPlatform {
    padding: 0 66px 200px;

    &__header {
      h3 {
        font-size: 44px;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .tradingPlatform {
    padding: 0 0 154px;

    &__header {
      margin-bottom: 48px;

      h3 {
        font-size: 32px;
        line-height: 28px;
      }

      p {
        font-size: 16px;
        line-height: 22px;
        max-width: 290px;
      }
    }
  }


  .decor {
    width: 100vw;
    height: 160%;
  }
}

@media only screen and (max-width: 767px) {
  .tradingPlatform {
    padding: 0 0 80px;

    &__header {
      margin-bottom: 27px;
      display: flex;
      flex-direction: column;
      row-gap: 16px;

      h3 {
        font-size: 24px;
        line-height: 21px;
        max-width: 80%;
      }

      p {
        font-size: 14px;
        line-height: 20px;
        max-width: 80%;
      }
    }
  }
}