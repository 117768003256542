// =========================
// VARIABLES & MIXINS
// =========================
$primary-color: #2451D1;
$secondary-color: #33436D;
$border-radius: 12px;
$transition-speed: 0.3s;
$spacing-md: 24px;
$spacing-lg: 40px;

// Media Breakpoints
$desktop: 1024px;
$tablet: 743px;

// Mixin for Flexbox
@mixin flex-center($justify: center, $align: center, $gap: 0px) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
  column-gap: $gap;
}

// =========================
// HEADER WRAPPER
// =========================
.headerWrapper {
  padding: 14px 90px 32px;
  background-size: 110% 200%;
  background-repeat: no-repeat;
  background-position: center top;

  @media (max-width: $desktop) {
    padding: $spacing-md $spacing-lg $spacing-md;
    background-size: 140% 225%;
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }

  @media (max-width: $tablet) {
    padding: $spacing-md 18px 10px;
    background-size: 180% 225%;
  }
}

// =========================
// LOGO
// =========================
.imgLogo {
  height: 17px;
}

.logo {
  font-size: 24px;
  font-weight: 900;
  font-family: "Audiowide", serif;
  display: block;
}

// =========================
// HEADER NAVIGATION
// =========================
.header {
  @include flex-center(space-between, center);

  &__menuBox {
    @include flex-center($gap: 30px);
  }

  &__menuBoxMob {
    @include flex-center(space-between, center);
    width: 100%;
    padding: 0 50px;

    @media (max-width: $tablet) {
      padding: 0;
    }
  }
}

.navigation {
  @include flex-center($gap: 14px);

  &__link, &__linkActive {
    position: relative;
    font-size: 14px;
    line-height: 17px;

    &:before {
      position: absolute;
      content: '';
      bottom: -10px;
      left: 50%;
      width: 0;
      height: 3px;
      border-radius: 3px;
      background-color: transparent;
      transition: all .3s;
    }

    &:after {
      position: absolute;
      content: '';
      bottom: -10px;
      left: 0;
      width: 100%;
      height: 3px;
      border-radius: 3px;
      background-color: transparent;
      transition: all .2s;
    }
  }

  &__linkActive:after {
    background-color: $primary-color;
  }

  &__link:hover, &__linkActive:hover {
    &:before {
      width: 100%;
      left: 0;
      background-color: $primary-color;
    }
  }
}

// =========================
// AUTH BUTTONS
// =========================
.login {
  padding: 5px;
}

.signUp {
  padding: 12px 26px;
  background-color: $secondary-color;
  border-radius: 10px;
}

// =========================
// SUBHEADER
// =========================
.subHeader {
  position: relative;
  display: flex;
  flex-direction: column;
  column-gap: 64px;
}

// =========================
// MOBILE STYLES
// =========================
.mobileNavigation {
  margin: 16px 0 0 16px;
}

.headerTitle {
  font-size: clamp(24px, 5vw, 30px);
  font-weight: 500;
  line-height: clamp(28px, 5vw, 35px);
  padding: 24px 0;
}
