.userBalance {
  display: flex;
  justify-content: flex-start;
  column-gap: 30px;
  align-items: center;
  position: relative;
  padding-left: 50px;
  border-left: 2px solid rgba(255, 255, 255, 0.08);
  min-width: 40%;
  //width: 50%;
}

.balances {
  min-width: 150px;

  &__dropBtn {
    font-size: 20px;
    line-height: 17px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    position: relative;

    span {
      opacity: 0.5;
      margin-right: 4px;
    }
  }

  &__main {
    font-weight: 500;
    font-size: 28px;
    line-height: 30px;
    margin-bottom: 8px;
  }

  &__second {
    line-height: 14px;
    opacity: 0.5;
    text-align: start;
  }
}

@media only screen and (max-width: 1580px) {
  .userBalance {
    padding-left: 40px;
  }
}

@media only screen and (max-width: 1360px) {
  .userBalance {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 1024px) {
  .userBalance {
    width: 100%;
    margin: 0 50px;
    padding-left: 0;
    border-left: none;
    justify-content: space-between;
  }
  .balances {
    &__main {
      align-items: flex-start;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .userBalance {
    width: 100%;
    margin: 0;
  }

  .balances {
    width: 100%;

    &__dropBtn {
      font-size: 16px;
      line-height: 14px;
      margin-bottom: 14px;
    }

    &__main {
      text-align: start;
      font-weight: 700;
      font-size: 24px;
      line-height: 22px;
    }
  }
}

.tokenStats {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin-top: 24px;
  width: 100%;

  p {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    span {
      font-size: 16px;
      line-height: 14px;
    }
  }
}

.dropdownShow, .dropdownHide {
  position: absolute;
  top: 100%;
  left: -2px;
  min-width: 160px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  height: 60px;
  transition: all .15s;
  overflow: hidden;
  z-index: 2;

  p {
    padding: 8px 4px;
    width: 100%;
    text-align: left;
    position: relative;
    white-space: nowrap;

    &:after {
      content: '';
      display: block;
      width: 0;
      position: absolute;
      bottom: 0;
      height: 1px;
      background-color: rgba(255, 255, 255, 0.5);
      transition: all .05s;
    }
  }

  p:hover {
    &:after {
      width: 95%;
    }
  }
}

.dropdownHide {
  height: 0;
}